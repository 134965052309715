<template lang="html">
  <v-card flat>
    <v-toolbar flat color="info">
        <v-toolbar-title>Editing {{themeName}}</v-toolbar-title>
    </v-toolbar>
    <!-- ISSUE CARD -->
    <v-card outlined class="ma-5">
      <v-card-title>THEME ISSUES</v-card-title>
      <v-card-text>
        <v-row v-for="issue in getIssues" :key="issue.id" no-gutters class="subtitle-1 entries">
          <v-col cols="4" md="1">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"> {{issue.active ? 'fas fa-door-open' : 'fas fa-door-closed'}}</v-icon>
              </template>
              <span>{{issue.active ? "Open" : "Closed"}}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="8" md="5">{{issue.created}}</v-col>
          <v-col cols="12" md="6">{{issue.request}}</v-col>
          <v-col cols="12" class="text-center text-md-right">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="changeIssueStatus(issue)">
                  <v-icon> {{issue.active ? 'fas fa-door-closed' : 'fas fa-door-open'}}</v-icon>
                </v-btn>
              </template>
              <span>{{issue.active ? "Close Issue" : "Open Issue"}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- THEME DATA -->
    <v-row no-gutters>
      <v-col cols="12" md="2" offset-md="10">
        <v-card outlined class="ma-5">
          <v-radio-group v-model="themeStatus" label="Theme Approved:">
            <v-radio value="true" label="Yes"></v-radio>
            <v-radio value="false" label="No"></v-radio>
            <v-radio value="pending" label="Pending"></v-radio>
          </v-radio-group>
        </v-card>        
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
          <form>
            <v-row>
              <v-col cols="12" md="8" offset-md="2">
                <v-text-field
                  name="themeName"
                  label="Theme Name"
                  id="themeName"
                  v-model="themeName"
                  type="text"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" md="8" offset-md="2">
                <v-text-field
                  name="themeDesc"
                  label="Theme Description"
                  id="themDesc"
                  v-model="themeDesc"
                  type="text"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8" offset-md="2">
                <v-textarea
                  name="themeNotes"
                  label="Theme Notes"
                  id="themeNotes"
                  v-model="themeNotes"
                  placeholder="Make notes about the theme, certain hero cosmetics, etc."
                  multi-line
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutter>
              <v-col cols="12" md="4" class="subtitle-1 text-center">
                <v-icon left color="red" large>far fa-hand-rock</v-icon>
                    Strength Heroes
                  <hr>
                  <v-checkbox v-for="hero in getStrHeroes" color="secondary" :key="hero.name" :label="hero.name" :value="hero.name" v-model="selectedHeroes" class="pl-1">
                  </v-checkbox>
              </v-col>
              <v-col cols="12" md="4" class="info subtitle-1 text-center">
                <v-icon left color="green" large>fab fa-google-wallet</v-icon>
                    Agility Heroes
                <hr>
                <v-checkbox v-for="hero in getAgiHeroes" color="secondary" :key="hero.name" :label="hero.name" :value="hero.name" v-model="selectedHeroes" class="pl-1">
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="4" class="subtitle-1 text-center">
                    <v-icon left color="blue" large>fas fa-magic</v-icon>
                    Int Heroes
                  <hr>
                  <v-checkbox v-for="hero in getIntHeroes" color="secondary" :key="hero.name" :label="hero.name" :value="hero.name" v-model="selectedHeroes" class="pl-1">
                  </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-alert type ="error" :value="!validateTheme" outlined dense>
                        ***Themes must have a <b>NAME</b>, <b>DESCRIPTION</b> and a <b>MINIMUM</b> of <b>FIVE</b> heroes selected to be submitted.
                    </v-alert>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutter>
                <v-col cols="12" md="6" offset-md="3" class="text-center">
                    <v-btn large outlined color="secondary" @click="updateTheme()" :disabled="!validateTheme">
                        <v-icon left>fas fa-share-square</v-icon>
                        Update Theme
                    </v-btn>
                </v-col>
            </v-row>
          </form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      selectedHeroes: this.themeData.heroes,
      themeName: this.themeData.name,
      themeDesc: this.themeData.description,
      themeNotes: this.themeData.notes,
      themeStatus: this.themeData.approved
    }
  },
  props: {
    themeData: Object
  },
  computed: {
    getAgiHeroes() {
        return this.$store.getters.getHeroes.filter(hero => hero.attribute == "Agility").sort((a, b) => {
            if (a.name > b.name){
                return 1
            }
            else {
                return -1
            }
        })
    },
    getIntHeroes(){
        return this.$store.getters.getHeroes.filter(hero => hero.attribute == "Intelligence").sort((a, b) => {
            if (a.name > b.name){
                return 1
            }
            else {
                return -1
            }
        })
    },
    getIssues(){
      return this.$store.getters.getIssues.filter(issue => issue.theme == this.themeData.name)
    },
    getStrHeroes(){
      return this.$store.getters.getHeroes.filter(hero => hero.attribute == "Strength").sort((a, b) => {
            if (a.name > b.name){
                return 1
            }
            else {
                return -1
            }
      })
    },
    getUser(){
      return this.$store.getters.getUser
    },
    validateTheme () {
      return this.themeName != "" && this.themeDesc !== "" && this.selectedHeroes.length >= 5
    }
  },
  methods: {
    changeIssueStatus(issue){
      issue.active      = !issue.active
      issue.resolvedBy  = this.getUser
      issue.resolved    = new Date().toString()
      this.$store.dispatch('changeIssueStatus', issue)
    },
    updateTheme () {
      let themeInfo = {
        id            : this.themeData.id,
        name          : this.themeName,
        description   : this.themeDesc,
        heroes        : this.selectedHeroes,
        notes         : this.themeNotes,
        approved      : this.themeStatus
      }
      this.$store.dispatch('updateTheme', themeInfo);
    }
  }
}
</script>

<style>
.entries:nth-child(odd){
    background: #d7dae5;
}
</style>